<template>
 <div class="min-h-screen flex flex-col bg-white">
  <main class="flex-grow flex flex-col justify-center max-w-3xl w-full mx-auto px-4 sm:px-6 lg:px-8">
   <div class="flex-shrink-0 flex justify-center">
    <a href="/" class="inline-flex">
     <img class="h-36 w-auto" :src="logo" alt="" />
    </a>
   </div>
   <div class="py-10" v-if="pbxMap && Object.keys(pbxMap).length > 0 && pbxMap.active">
    <div class="text-center">
     <p class="text-base font-semibold encom_pink_text uppercase tracking-wide">temporairement indisponible</p>
     <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Service temporairement indisponible</h1>
     <p class="text-left mt-5 text-base text-gray-500">
      Le service Expert Statistics est temporairement indisponible en raison d'une migration vers une nouvelle infrastructure. Cette mise à jour est essentielle pour améliorer la performance, la sécurité et la fiabilité de notre service, afin de mieux répondre à vos attentes. Toutes les données et
      configurations de votre compte seront soigneusement transférées vers la nouvelle infrastructure, et vous retrouverez l'accès à toutes les fonctionnalités. La réouverture du service est prévue au plus tard le lundi 18 octobre 2023.
     </p>
     <div class="mt-6">
      <router-link to="/" class="text-base font-medium encom_primary_text">{{ $t("goBackHome") }}<span aria-hidden="true"> &rarr;</span></router-link>
     </div>
    </div>
   </div>
   <div class="py-10" v-else>
    <div class="text-center">
     <p class="text-base font-semibold encom_pink_text uppercase tracking-wide">temporairement indisponible</p>
     <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Service temporairement indisponible for <span class="font-normal">{{ hostName ? hostName : "host" }}</span></h1>
     <p class="text-left mt-5 text-base text-gray-500">
      Le service Expert Statistics est temporairement indisponible sur le serveur 3CX <span class="font-bold">{{ hostName ? hostName : "host" }}</span>. Cette interruption est probablement due à une mise à jour nécessitant un recalcul des données. N'hésitez pas à créer un ticket auprès de notre service d'assistance pour obtenir
      plus d'informations ou si la situation persiste.
     </p>
     <div class="mt-6">
      <router-link to="/" class="text-base font-medium encom_primary_text">{{ $t("goBackHome") }}<span aria-hidden="true"> &rarr;</span></router-link>
     </div>
    </div>
   </div>
  </main>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
const logo = localStorage.getItem("logo");
export default {
 name: "Error404",
 props: ["msg", "status"],
 data() {
  return {
   logo,
  };
 },
 methods: {
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   this.$store.dispatch("user", {});
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("token", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
 },
 computed: {
  ...mapGetters(["pbxMap", "hostName"]),
 },
};
</script>

<style></style>
